<script lang="ts" setup>
import { ILink, TagCloud, Button } from "@frontiers-vue3/public-pages";
import { getCategoriesEligibleForPosts } from "~/helpers";
import { mapCategoryToLink } from "~/mappers/models";
import { ICategory } from "~/types/page-service";

const isOpen = ref(false);
const totalInitalVisibleCategories = 10;
const categories = useState<Array<ICategory>>("categories");

const categoryTags = computed((): Array<ILink> => {
  const categoriesForPost = getCategoriesEligibleForPosts(categories.value);
  return categoriesForPost.map((c) => mapCategoryToLink(c));
});

const visibleCategories = computed((): Array<ILink> => {
  return !isOpen.value
    ? categoryTags.value.slice(0, totalInitalVisibleCategories)
    : categoryTags.value;
});

const openCategories = () => {
  isOpen.value = true;
};
</script>

<template>
  <TagCloud :tags="visibleCategories" header="h3" />
  <Button
    v-if="!isOpen"
    text="View all categories"
    type="Link"
    color="Grey"
    size="Medium"
    @click="openCategories"></Button>
</template>

<style lang="scss" scoped>
.Button {
  margin: 16px 0;
  --color-button: #{$textSecondary};
}
</style>
